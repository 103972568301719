import React from "react"
import PropTypes from "prop-types"
import shortid from "shortid"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const MainNavigation = ({ locale, current }) => {
  const data = useStaticQuery(query)

  const menu = data.mainMenu.nodes
    .filter(node => node.lang === locale)
    .map(node => node.data.items)
    .pop()

  let foundLink = false

  const menuItems = menu
    .slice(0)
    .reverse()
    .map(item => {
      const newItem = { ...item }
      const match = current.startsWith(item.item_url)

      if (match && !foundLink) {
        foundLink = true
        newItem.active = true
      }

      return newItem
    })
    .reverse()

  return (
    <nav id="main-nav">
      {menuItems.map(item => (
        <Link
          key={shortid.generate()}
          to={item.item_url}
          className={`nav-block ${item.active ? "active" : ""}`}
        >
          <Img
            className="nav-inner"
            alt={item.item_image.alt}
            fluid={item.item_image.fluid}
          />

          <span className="nav-title">{item.item_name}</span>
        </Link>
      ))}
    </nav>
  )
}

export default MainNavigation

const query = graphql`
  query LayoutQuery {
    mainMenu: allPrismicMainMenu(filter: { lang: { in: "ro" } }) {
      nodes {
        lang
        data {
          items {
            item_url
            item_name
            item_image {
              fluid(maxWidth: 300) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`

MainNavigation.propTypes = {
  locale: PropTypes.string.isRequired,
  current: PropTypes.string.isRequired,
}
