import React from "react"

import Layout from "./src/components/Layout"
import { LocaleContext } from "./src/contexts/LocaleContext"
import i18n from "./config/i18n"

const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { locale },
  } = props

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <Layout {...props}>{element}</Layout>
    </LocaleContext.Provider>
  )
}

export default wrapPageElement
