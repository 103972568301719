module.exports = {
  ro: {
    path: "",
    locale: "ro",
    siteLanguage: "ro",
    ogLang: "ro_RO",
    hrefLang: "ro-RO",
    ogUrl: "https://www.emilmuresan.ro/ro/",
    title: "Emil Mureșan - Artist Plastic",
    description:
      "Lucrări din pânză de paianjăn, rădăcini și sculpturia ale artistului Emil Mureșan",
    headline: "Galeria de artă",
    addressLine: "Turnul Pietrarilor, str. J. Honterus, Mediaș, România",
    homeName: "Acasă",
    organizationName: "Emil Muresan | Galeria de artă",
    organizationAddressLocality: "Mediaș, România",
    organizationStreetAddress:
      "Turnul Pietrarilor, str. J. Honterus, Mediaș, România",
  },
}
