import React, { useEffect } from "react"
import PropTypes from "prop-types"

import MainNavigation from "./MainNavigation"

import "../styles/base.scss"

const Layout = ({ path, children, pageContext: { locale } }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)

      window.addEventListener("resize", () => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
      })
    }
  })

  return (
    <>
      <div id="wrapper">
        <MainNavigation locale={locale} current={path} />
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  path: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
}

export default Layout
