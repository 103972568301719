// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-category-js": () => import("./../../../src/templates/aboutCategory.js" /* webpackChunkName: "component---src-templates-about-category-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/aboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-news-category-js": () => import("./../../../src/templates/newsCategory.js" /* webpackChunkName: "component---src-templates-news-category-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/newsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-portfolio-category-js": () => import("./../../../src/templates/portfolioCategory.js" /* webpackChunkName: "component---src-templates-portfolio-category-js" */),
  "component---src-templates-portfolio-page-js": () => import("./../../../src/templates/portfolioPage.js" /* webpackChunkName: "component---src-templates-portfolio-page-js" */)
}

